import { get, cloneDeep, remove } from 'lodash';
import {
  ADDITIONAL_INVESTMENT,
  basisTypes,
  EXTERNAL_SOURCE,
  initializerTypes,
  INITIALIZER_BANKER,
  CLIENT_INITIATIVE,
  CLIENT,
  REPRESENTATIVE,
} from 'constants/receiveInfoOptions';
import {
  CONSULTATIVE_AGREEMENT,
  FULL_POWER_OF_ATTORNEY,
  SECURITIES_BROKERAGE_CONTRACT,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
  CONSULTATIVE_AGREEMENT_TYPE,
  SECURITIES_BROKERAGE_CONTRACT_TYPE,
  CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE,
  FULL_POWER_OF_ATTORNEY_TYPE,
} from 'constants/contractNames';
import {
  getStructuredOrderErrors,
  getWarrantOrderErrors,
  getShareOrderErrors,
  getFundOrderErrors,
  getMinutesOfInvestmentServiceErrors,
} from 'core/validations';
import { selectCurrentPortfolio, selectDefaultAccount } from 'features/portfolio/portfolioSelectors';
import { isBond, isListed } from 'core/portfolios';
import { RootState } from 'types/rootState';
import { FUND, SHARE, WARRANT } from 'constants/instrumentForms';
import {
  AppropriatenessResult,
  CustomerAnswer,
  OrderSideType,
  Questionnaire,
  TouchedFields,
  ValidationErrorValue,
} from 'types/orderDialogState';
import { listed } from 'constants/shareOptions';
import { FeeResponse, MinutesOfInvestmentServiceType, OrderLineType } from 'types/ordersState';
import { apiCall } from 'core/apiCall';
import { selectCustomer } from 'features/profile/profileSelectors';
import { TAALERI_EXPERTS_EMAIL } from 'constants/emails';
import { ChangePairResponse, GetNominalValueResponse, SuitabilityResult } from 'features/orderDialog/orderDialogTypes';
import { PortfolioDetailsById } from 'types/portfolioState';
import { ELIGIBLE_COUNTERPARTY } from 'constants/customerCategorisation';
import { CONVENER, INFO, LOCATION, MINUTES_OF_INVESTMENT_SERVICE, PARTICIPANTS } from 'constants/orderFields';
import { pathToProperty } from 'core/functions';

export const getMoneySource = (accountType: string, contractName: string) => {
  if (!accountType || accountType === '03') {
    return EXTERNAL_SOURCE;
  } else if (contractName === FULL_POWER_OF_ATTORNEY) {
    return ADDITIONAL_INVESTMENT;
  }
};

const moisFormDirty = (touchedFields: TouchedFields, mois: MinutesOfInvestmentServiceType) => {
  const someMOISFieldTouched =
    touchedFields[pathToProperty([MINUTES_OF_INVESTMENT_SERVICE, PARTICIPANTS])] ||
    touchedFields[pathToProperty([MINUTES_OF_INVESTMENT_SERVICE, LOCATION])] ||
    touchedFields[pathToProperty([MINUTES_OF_INVESTMENT_SERVICE, INFO])] ||
    touchedFields[pathToProperty([MINUTES_OF_INVESTMENT_SERVICE, CONVENER])];

  const someFieldHasContent =
    mois[PARTICIPANTS].length > 0 || mois[LOCATION].length > 0 || mois[INFO].length > 0 || mois[CONVENER].length > 0;

  if (someMOISFieldTouched && someFieldHasContent) {
    return true;
  }
  return false;
};

export const getValidationErrors = (state: RootState, orderLine?: OrderLineType): ValidationErrorValue[] => {
  const currentPortfolio = selectCurrentPortfolio(state);

  const orderDialogState = state.orderDialog;
  const { editor, structuredMinimumSum, structuredStep, defaultFee, privateEquityMaxSum } = orderDialogState;

  const isInsurancePortfolio = currentPortfolio?.isInsurancePortfolio;
  const defaultAccount = selectDefaultAccount(state);

  const assignment = orderLine ?? editor.toJS();

  let errors: ValidationErrorValue[] = [];

  if (isListed(assignment.instrumentForm, assignment.side)) {
    errors = getShareOrderErrors(assignment);
  } else if (assignment.instrumentForm === FUND) {
    errors = getFundOrderErrors(assignment, defaultAccount);
  } else if (assignment.instrumentForm === WARRANT) {
    errors = getWarrantOrderErrors(assignment, defaultFee, structuredMinimumSum, structuredStep, defaultAccount);
  } else {
    errors = getStructuredOrderErrors(
      assignment,
      structuredMinimumSum,
      structuredStep,
      privateEquityMaxSum,
      isInsurancePortfolio
    );
  }

  const isDiscretionaryPortfolio = currentPortfolio?.contractType === FULL_POWER_OF_ATTORNEY_TYPE;

  if (
    assignment.minutesOfInvestmentService &&
    (!isDiscretionaryPortfolio ||
      (isDiscretionaryPortfolio &&
        moisFormDirty(state.orderDialog.touchedFields.toJS(), assignment.minutesOfInvestmentService)))
  ) {
    errors = errors.concat(getMinutesOfInvestmentServiceErrors(assignment.minutesOfInvestmentService));
  }
  return errors;
};

export const getInstrumentData = (currentForm: string, contractName: string) => {
  if (contractName === SECURITIES_BROKERAGE_CONTRACT && !listed.includes(currentForm)) {
    return {
      instrumentForm: SHARE,
      financialInstrumentId: undefined,
      financialInstrumentForm: undefined,
      financialInstrumentName: undefined,
    };
  }
  return {};
};

export const getInitializerTypes = (contractName: string) => {
  const opts = cloneDeep(initializerTypes);
  if (
    contractName === CONSULTATIVE_AGREEMENT ||
    contractName === SECURITIES_BROKERAGE_CONTRACT ||
    contractName === CUSTOMER_RELATIONSHIP_AGREEMENT
  ) {
    remove(opts, (o) => o.value === INITIALIZER_BANKER);
  }
  if (contractName === FULL_POWER_OF_ATTORNEY) {
    remove(opts, (o) => o.value === CLIENT);
    remove(opts, (o) => o.value === REPRESENTATIVE);
  }
  return opts;
};

export const getBasisTypes = (contractType: string | undefined, customerCategorisation: string | undefined) => {
  const opts = cloneDeep(basisTypes);
  if (contractType === CONSULTATIVE_AGREEMENT_TYPE) {
    remove(opts, (o) => o.value === CLIENT_INITIATIVE);
  }
  if (contractType === SECURITIES_BROKERAGE_CONTRACT_TYPE) {
    remove(opts, (o) => o.value !== CLIENT_INITIATIVE);
  }
  if (contractType === CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE && customerCategorisation === ELIGIBLE_COUNTERPARTY) {
    remove(opts, (o) => o.value !== CLIENT_INITIATIVE);
  }
  return opts;
};

export const getFees = (
  customerId: string,
  portfolioId: string,
  financialInstrumentId: string,
  side: OrderSideType,
  token: string
) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/reports/default-fees/transactions/${customerId}?portfolioId=${portfolioId}&financialInstrumentId=${financialInstrumentId}&side=${side}`,
  }) as Promise<FeeResponse>;
};

export const getNominalValueApiCall = (financialInstrumentId: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/reports/products/structured/${financialInstrumentId}`,
  }) as Promise<GetNominalValueResponse>;
};

export const getChangePairs = (instrumentId: string, clientId: string, portfolioId: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `api/v1/orders/instruments/${instrumentId}/change-pairs?clientId=${clientId}&portfolioId=${portfolioId}&side=Exchange`,
  }) as Promise<ChangePairResponse[]>;
};

export const getSuitability = (
  customerId: string,
  portfolioId: string,
  financialInstrumentId: string,
  side: OrderSideType,
  token: string,
  orderBasis?: string
) => {
  let apiUrl = `/api/v1/orders/instruments/suitabilities?customerId=${customerId}&portfolioId=${portfolioId}&instrumentIds=${financialInstrumentId}&side=${side}`;
  apiUrl = orderBasis ? `${apiUrl}&orderBasis=${orderBasis}` : apiUrl;
  return apiCall({
    method: 'get',
    token,
    path: apiUrl,
  }) as Promise<SuitabilityResult[]>;
};

export const getContractName = (state: RootState, portfolioId: string) =>
  get(state, ['portfolio', 'portfolioDetailsById', portfolioId, 'contractName'], '');

export const getCapitalCallEmail = (state: RootState) => {
  return state.profile.customer.getIn(['contacts'], [{ email: '' }])[0]?.email;
};

export const getCustomerCategorisation = (state: RootState) => state.profile.customer.get('customerCategorisation');

export const getBillingAddress = (state: RootState) => {
  const defaultAccount = selectDefaultAccount(state);
  if (defaultAccount && defaultAccount.accountType === '03') {
    return selectCustomer(state).getIn(['contacts'], [{ email: '' }])[0].email;
  }
  return TAALERI_EXPERTS_EMAIL;
};

export const getCurrency = (assignment: OrderLineType, portfolioDetailsById: PortfolioDetailsById) => {
  if (isBond(assignment.financialInstrumentForm)) {
    return assignment.instrumentCurrency;
  }
  return get(portfolioDetailsById, [assignment.portfolioId, 'withdrawalCurrency'], '');
};

export const putAppropriatenessAssesment = (
  customerId: string,
  token: string,
  customerAnswers: CustomerAnswer | undefined
) => {
  const path = `/api/v1/orders/instruments/suitabilities/customer-appropriatenessassesment?customerId=${customerId}`;
  return apiCall({
    method: 'put',
    token,
    path,
    data: customerAnswers,
  }) as Promise<AppropriatenessResult>;
};

export const getAppropriatenessAssesmentTest = (
  customerId: string,
  token: string,
  productCategoryId: string | undefined,
  language: string
) => {
  const path = `/api/v1/orders/instruments/suitabilities/appropriatenessassesment-test?productCategoryId=${productCategoryId}&language=${language}`;
  return apiCall({
    method: 'get',
    token,
    path,
  }) as Promise<Questionnaire>;
};
