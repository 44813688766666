import React, { useState, useEffect } from 'react';
import Select from 'react-select-old';
import translate from 'counterpart';
import classnames from 'classnames';
import styles from './InstrumentSearch.scss';
import { ChangePairOption } from 'types/orderDialogState';
import { OrderInstrument } from 'features/orderDialog/orderDialogTypes';
import { InstrumentForm } from 'constants/instrumentForms';
import { ModalDialog } from 'features/common/ModalDialog';
import StyledButton from 'features/common/styledComponents/StyledButton';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { useAppDispatch } from 'core/hooks';
import { OrderLineType } from 'types/ordersState';
import { Spinner } from 'features/common/Spinner';
import { colors } from 'styles/colors';

interface Props {
  onInstrumentChange: (params: OrderInstrument) => void;
  value: string | undefined;
  instrumentForm?: InstrumentForm;
  options: ChangePairOption[];
  isLoading: boolean;
  getOptions: (s: string) => void;
  'data-testkey'?: string;
  searchDisabled: boolean;
  isExhangeSearch?: boolean;
  receiveMethod?: string;
  setFocus?: () => void;
  assignment?: OrderLineType;
  assignmentLanguage?: string;
}

export const InstrumentSearch = ({
  onInstrumentChange,
  value,
  instrumentForm,
  options,
  isLoading,
  getOptions,
  'data-testkey': dataTestKey,
  searchDisabled,
  isExhangeSearch,
  receiveMethod,
  setFocus,
  assignment,
  assignmentLanguage,
}: Props) => {
  const dispatch = useAppDispatch();
  const [selectOptions, setSelectOptions] = useState<ChangePairOption[]>([]);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorDialogText, setErrorDialogText] = useState('');

  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const isFromBuySell = assignment?.buyOrSellClicked === true;

  const [showLoadingDialog, setShowLoadingDialog] = useState(false);

  const [showSuitabilityInfoDialog, setShowSuitabilityInfoDialog] = useState(false);
  const [suitabilityInfoDialogText, setSuitabilityInfoDialogText] = useState('');

  useEffect(() => {
    setSelectOptions([]);
  }, [instrumentForm, value, receiveMethod, assignmentLanguage]);

  useEffect(() => {
    hideErrorDialog();
    const fromBuySellAndEmpty = selectOptions.length === 0 && isFromBuySell;

    if (options.length > 0) {
      setSelectOptions(options);
      hideLoadingDialog();
      hideErrorDialog();
      if (isFromBuySell) {
        const selectedOption = options[0];
        if (selectedOption && selectedOption.subLabel) {
          openInfoDialog(selectedOption);
        }
        setFocus && setFocus();
        resetBuySell();
        setIsLoaded(false);
        setIsError(false);
      }
    } else {
      if (isFromBuySell) {
        hideErrorDialog();
        hideLoadingDialog();
        setFocus && setFocus();
        if (fromBuySellAndEmpty && isLoading) {
          openLoadingDialog();
        }
        if (fromBuySellAndEmpty && !isLoading && isLoaded) {
          setIsError(true);
          setIsLoaded(true);
        } else {
          if (isLoading && value) {
            openLoadingDialog();
            setIsLoaded(true);
          } else {
            setIsLoaded(false);
          }
        }
      }
    }

    if (fromBuySellAndEmpty && !isLoading && isLoaded && isError) {
      hideLoadingDialog();
      hideErrorDialog();
      setFocus && setFocus();
      setShowErrorDialog(true);
      setErrorDialogText(`Instrumenttia ${value} ei löytynyt, toimeksiantoa ei voi jatkaa.`);
    }
  }, [options, isLoading, assignment?.buyOrSellClicked, isError]);

  const resetBuySell = () => {
    dispatch(setEditorValue('buyOrSellClicked', false));
  };

  const closeErrorDialog = () => {
    hideErrorDialog();
    setSelectOptions([]);
    resetBuySell();
    setIsLoaded(false);
    setIsError(false);
    dispatch(setEditorValue('financialInstrumentId', ''));
    dispatch(setEditorValue('financialInstrumentName', ''));
    setFocus && setFocus();
  };

  const closeSuitabilityInfoDialog = () => {
    setShowSuitabilityInfoDialog(false);
    setSuitabilityInfoDialogText('');

    resetBuySell();
    setIsLoaded(false);
    setIsError(false);

    const selectedOption = options[0];
    if (!selectedOption.alwaysEnable) {
      dispatch(setEditorValue('isSuitable', undefined));
      dispatch(setEditorValue('financialInstrumentId', ''));
      dispatch(setEditorValue('financialInstrumentName', ''));
      setFocus && setFocus();
    }
  };

  const hideErrorDialog = () => {
    setShowErrorDialog(false);
    setErrorDialogText('');
  };

  const hideLoadingDialog = () => {
    setShowLoadingDialog(false);
  };

  const openLoadingDialog = () => {
    setShowLoadingDialog(true);
  };

  const loadingContent = () => {
    return (
      <div>
        {`Haetaan instrumenttia ${assignment?.financialInstrumentName}...`}
        <br />
        <br />
        <Spinner className={'spinner'} size={30} position="center" color={colors.ptGreen} />
      </div>
    );
  };

  const openInfoDialog = (option: ChangePairOption) => {
    if (!option.alwaysEnable) {
      setShowSuitabilityInfoDialog(true);
      const text = `Varmista instrumentin ${assignment?.financialInstrumentName} soveltuvuus! ${option.subLabel}`;
      setSuitabilityInfoDialogText(text);
    }
  };

  const placeholderTextSearchOrSearchingBuySell =
    assignment?.buyOrSellClicked === true && value && isLoading
      ? translate('order.searchingForInstrument', { value })
      : translate('order.searchForInstrument');
  const placeholderText = isExhangeSearch ? translate('order.searchExchange') : placeholderTextSearchOrSearchingBuySell;
  const disabledPlaceholderText = !instrumentForm
    ? `${translate('order.selectBasis')} ${translate('order.instrumentForm')}`
    : `${translate('order.selectBasis')} ${translate('order.basis')}`;

  const optionRenderer = (option: ChangePairOption) => {
    return (
      <div>
        <div>{option.label}</div>
        {option.disabled || option.alwaysEnable ? (
          <div
            style={{
              fontStyle: 'italic',
              fontSize: '.85rem',
            }}
          >
            {option.subLabel}
          </div>
        ) : null}
      </div>
    );
  };

  const errorActions = [
    <StyledButton variant="text" onClick={closeErrorDialog} key={'Peruuta'} data-testkey="modal-button-cancel">
      {translate('common.cancelAction')}
    </StyledButton>,
  ];

  const suitabilityInfoActions = [
    <StyledButton
      variant="text"
      onClick={closeSuitabilityInfoDialog}
      key={'Peruuta'}
      data-testkey="modal-button-cancel"
    >
      {'OK'}
    </StyledButton>,
  ];

  return (
    <section>
      <ModalDialog
        title={'Odota hetki, haetaan instrumenttia'}
        open={showLoadingDialog}
        content={loadingContent()}
        actions={<div key={'NoOp'} />}
      />
      <ModalDialog
        title={'Virhe instrumentin haussa'}
        open={showErrorDialog}
        content={errorDialogText}
        actions={errorActions}
      />
      <ModalDialog
        title={'Varmista soveltuvuus!'}
        open={showSuitabilityInfoDialog}
        content={suitabilityInfoDialogText}
        actions={suitabilityInfoActions}
      />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-expect-error*/}
      <Select
        onInputChange={getOptions}
        onChange={onInstrumentChange}
        onClick={onInstrumentChange}
        value={value}
        ignoreAccents={false}
        placeholder={searchDisabled ? disabledPlaceholderText : placeholderText}
        noResultsText={isLoading ? translate('order.loadingPlaceholder') : translate('order.noResultsText')}
        clearValueText={translate('order.clearValueText')}
        searchingText={translate('order.searchingText')}
        searchPromptText={translate('order.searchPromptText')}
        loadingPlaceholder={translate('order.loadingPlaceholder')}
        optionRenderer={optionRenderer}
        cache={false}
        autoload={false}
        className={classnames('Select-white', styles.search)}
        isLoading={isLoading}
        onSelectResetsInput={false}
        onBlurResetsInput={true}
        inputProps={{ 'data-testkey': dataTestKey }}
        instrumentForm={instrumentForm}
        options={selectOptions}
        disabled={searchDisabled}
      />
    </section>
  );
};
