import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import classNames from 'classnames/bind';
import StyledRadioButton from './styledComponents/StyledRadioButton';
import StyledFormControlLabel from './styledComponents/StyledFormControlLabel';
import styles from './RadioButtons.scss';

const cx = classNames.bind(styles);

interface RadioButtonOption {
  value: string;
  label: string;
}

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  options: RadioButtonOption[];
  horizontal: boolean;
  value?: string;
  name: string;
  disabled?: boolean;
}

const RadioButtons = ({ onChange, options, horizontal = false, value, name, disabled = false }: Props) => (
  <RadioGroup defaultValue={value} value={value} className={cx({ horizontal })} onChange={onChange} name={name} row>
    {options.map((option) => (
      <StyledFormControlLabel
        value={option.value}
        control={<StyledRadioButton />}
        label={option.label}
        key={option.value}
        disabled={disabled}
      />
    ))}
  </RadioGroup>
);

export default RadioButtons;
