import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Map, Set } from 'immutable';
import classnames from 'classnames';
import moment from 'moment';
import Refresh from '@mui/icons-material/Refresh';
import translate from 'counterpart';
import { Link } from 'react-router';
import styled from 'styled-components';
import { NavBar } from 'features/common/NavBar';
import {
  selectFormValues,
  selectFormErrors,
  selectFormTouched,
  selectBisnodeValues,
  selectNotFoundFromBisnode,
  selectFetchingFromBisnode,
  selectHeader,
  selectIsSaving,
  selectHasCrmIdentity,
  selectCustomerType,
  selectLastScreened,
  selectIsPersonScreeningInProgress,
} from 'features/profileEdit/profileEditUtils';
import {
  loadProcurationForm,
  loadCustomerForm,
  setCustomerForm,
  validation,
  changeSectionField,
  getBisnodeData,
  getCompanyRegistryData,
  getProcurationInfo,
  loadStakeholders,
  getCompanyIdentifyAndScreen,
  updateStakeholders,
  screenPerson,
  updateCustomerData,
  copyValues,
  saveArrayEditorValue,
  updateStatuses,
  updateTradeRegistry,
  loadDocuments,
  getStatusesPdf,
  getTradeRegistryPdf,
} from 'features/profileEdit/profileEditActions';
import { privateProfile } from 'constants/privateProfile';
import { companyProfile } from 'constants/companyProfile';
import { companyProfileWoId } from 'constants/companyProfileWoId';
import { privateProfileWoSsn } from 'constants/privateProfileWoSsn';
import styles from './ProfileEditorView.scss';
import TabContent from 'features/profileEdit/components/TabContent';
import { PERSON } from 'constants/customerTypes';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { Spinner } from 'features/common/Spinner';
import { RootState } from 'types/rootState';
import { Template, Contract, Value } from 'types/ordersState';
import { ImmutableContract } from 'types/profileState';
import { Locale, Schema } from 'types/contractsState';

interface Props {
  formValues: ImmutableContract;
  bisnodeValues: Map<string, object>;
  formErrors: List<string>;
  formTouched: Set<string>;
  params: { id: string };
  loadCustomerForm: (customerId: string) => void;
  changeSectionField: (key: string, value: Value, schema: Schema) => void;
  getBisnodeData: (value: string) => void;
  getCompanyRegistryData: (value: string) => void;
  updateCustomerData: (customerId: string, formValues: Contract, customerType: string) => void; // FIX
  notFoundFromBisnode: boolean;
  fetchingFromBisnode: boolean;
  copyValues: (bisnodeValues: object, schema: Schema) => void;
  isSaving: boolean;
  hasCrmIdentity: boolean;
  header: string;
  saveArrayEditorValue: () => void;
  customerType: string;
  getProcurationInfo: (value: string) => void;
  lastScreened: string;
  screenPerson: (customerId: string) => void;
  isPersonScreeningInProgress: boolean;
  validation: (schema: Schema) => void;
  isBusy: boolean;
  contractLanguage: Locale;
}

class ProfileEditorView extends Component<Props> {
  componentDidMount() {
    const { params, formValues, loadCustomerForm } = this.props;
    if (formValues.isEmpty()) {
      loadCustomerForm(params.id);
    }
  }

  saveForm = () => {
    const { formValues, params, updateCustomerData, customerType } = this.props;
    updateCustomerData(params.id, formValues.toJS(), customerType);
  };

  render() {
    const { params, header, isSaving, hasCrmIdentity, customerType, formErrors, isBusy, contractLanguage } = this.props;

    let cancelTarget;
    if (customerType === PERSON) {
      cancelTarget = `/customer/${params.id}`;
    } else {
      cancelTarget = `/customer/${params.id}/summary`;
    }

    const rightContent = (
      <div>
        <Link to={cancelTarget} className={styles.rightBtn}>
          Peruuta
        </Link>
        {isSaving ? (
          <button className={styles.btnSave}>
            <Spinner color="white" />
          </button>
        ) : (
          <button
            className={styles.btnSave}
            onClick={this.saveForm}
            disabled={formErrors.size > 0 || isBusy}
            data-testkey="button-save-registry-data"
          >
            <span>
              Tallenna
              <br />
              järjestelmään
            </span>
          </button>
        )}
      </div>
    );

    let template: Template;
    let navLeftContent;
    if (customerType === PERSON) {
      template = hasCrmIdentity ? privateProfile : privateProfileWoSsn;
      navLeftContent = <div>{header} - Väestörekisteritiedot</div>;
    } else {
      template = hasCrmIdentity ? companyProfile : companyProfileWoId;
      navLeftContent = <div>{header} - Tietojen päivitys</div>;
    }

    let content;

    const sections = template.form.items.map((section, index) => (
      <section key={`Wrap${index}`}>
        <div className={classnames('row', styles.headerRow)}>
          <div className="columns small-12">
            <h6>{section.title.fi}</h6>
            <hr />
          </div>
        </div>
        <TabContent
          section={section}
          template={template}
          sectionIndex={index}
          formValues={this.props.formValues}
          bisnodeValues={this.props.bisnodeValues}
          formErrors={this.props.formErrors}
          formTouched={this.props.formTouched}
          changeSectionField={this.props.changeSectionField}
          getBisnodeData={this.props.getBisnodeData}
          getCompanyRegistryData={this.props.getCompanyRegistryData}
          notFoundFromBisnode={this.props.notFoundFromBisnode}
          fetchingFromBisnode={this.props.fetchingFromBisnode}
          copyValues={this.props.copyValues}
          saveArrayEditorValue={this.props.saveArrayEditorValue}
          customerType={this.props.customerType}
          getProcurationInfo={this.props.getProcurationInfo}
          validation={this.props.validation}
          contractLanguage={contractLanguage}
        />
      </section>
    ));
    content = sections;

    if (customerType === PERSON) {
      content = (
        <div>
          {content}
          <div className={classnames('row', styles.headerRow)}>
            <div className="columns small-7">
              <h6>Pakotelistan ja poliittisen vaikutusvallan tarkistus</h6>
              <button className={styles.actionButton} onClick={() => this.props.screenPerson(params.id)}>
                {this.props.isPersonScreeningInProgress ? (
                  <Spinner color="white" size={30} />
                ) : (
                  [
                    <Refresh
                      style={{
                        width: '15px',
                        height: '15px',
                        verticalAlign: 'middle',
                        marginRight: '.6rem',
                      }}
                      key="icon"
                      sx={{
                        color: '#fff',
                      }}
                    />,
                    <span key="text">{translate(`customer.update`)}</span>,
                  ]
                )}
              </button>
            </div>
            <div className="columns small-5 fieldList">
              Viimeksi tarkastettu:&nbsp;
              {this.props.lastScreened ? moment(this.props.lastScreened).format('DD.MM.YYYY HH:mm') : '-'}
            </div>
          </div>
        </div>
      );
    }

    return (
      <section>
        <NavBar rightContent={rightContent} leftContent={navLeftContent} />
        <ErrorBoundary>
          {this.props.isBusy ? <StyledSpinner size={100} /> : <div className={styles.content}>{content}</div>}
        </ErrorBoundary>
      </section>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    formValues: selectFormValues(state),
    formErrors: selectFormErrors(state),
    formTouched: selectFormTouched(state),
    bisnodeValues: selectBisnodeValues(state),
    notFoundFromBisnode: selectNotFoundFromBisnode(state),
    fetchingFromBisnode: selectFetchingFromBisnode(state),
    header: selectHeader(state),
    isSaving: selectIsSaving(state),
    hasCrmIdentity: selectHasCrmIdentity(state),
    customerType: selectCustomerType(state),
    lastScreened: selectLastScreened(state),
    isPersonScreeningInProgress: selectIsPersonScreeningInProgress(state),
    isBusy: state.profileEdit.isBusy,
    contractLanguage: state.common.locale,
  };
}

const mapDispatchToProps = {
  loadProcurationForm,
  loadCustomerForm,
  setCustomerForm,
  validation,
  changeSectionField,
  getBisnodeData,
  getCompanyRegistryData,
  getProcurationInfo,
  loadStakeholders,
  getCompanyIdentifyAndScreen,
  updateStakeholders,
  screenPerson,
  updateCustomerData,
  copyValues,
  saveArrayEditorValue,
  updateStatuses,
  updateTradeRegistry,
  loadDocuments,
  getStatusesPdf,
  getTradeRegistryPdf,
};

const StyledSpinner = styled(Spinner)`
  margin-top: 100px;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditorView);
