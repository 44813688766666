import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { SelectInstrumentForm } from './SelectInstrumentForm';
import { InstrumentSearch } from './InstrumentSearch';
import { InstrumentOption } from 'types/orderDialogState';
import { OrderInstrument } from 'features/orderDialog/orderDialogTypes';
import {
  CO_INVESTMENTS,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
  FUND,
  InstrumentForm,
  PRIVATE_EQUITY,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  STRUCTURED_PRODUCT,
  WARRANT,
} from 'constants/instrumentForms';
import { PortfolioById } from 'types/portfolioState';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { selectEditor, selectContractLanguage } from 'features/orderDialog/orderDialogSelectors';
import { isListed } from 'core/portfolios';
import {
  searchInstrument,
  searchPrivateEquity,
  searchShare,
  searchStructuredProduct,
  searchWarrant,
} from 'features/orderDialog/orderDialogActions';
import { useAppDispatch } from 'core/hooks';
import {
  CONSULTATIVE_AGREEMENT_TYPE,
  CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE,
  CONSULTATIVE_AGREEMENT,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
} from 'constants/contractNames';

const DEBOUNCE_DELAY = 500;

interface Props {
  focusRef: React.MutableRefObject<HTMLElement | undefined>;
  instrumentForm: InstrumentForm;
  instrumentOptions: InstrumentOption[];
  isLoadingOptions: boolean;
  onInstrumentChange: (params: OrderInstrument) => void;
  selectedPortfolio: PortfolioById;
  orderBasis: string;
  receiveMethod?: string;
  setFocus?: () => void;
  customerType: string;
}

export const Instrument = ({
  instrumentForm,
  focusRef,
  onInstrumentChange,
  instrumentOptions,
  isLoadingOptions,
  selectedPortfolio,
  orderBasis,
  receiveMethod,
  setFocus,
  customerType,
}: Props) => {
  const dispatch = useAppDispatch();

  const customerId = useSelector(selectCustomerId);
  const assignment = useSelector(selectEditor).toJS();
  const language = useSelector(selectContractLanguage);

  useEffect(() => {
    assignment.financialInstrumentId ? getSearchInstrumentsResult()(assignment.financialInstrumentId) : null;
  }, [
    instrumentForm,
    customerId,
    assignment.portfolioId,
    assignment.side,
    assignment.orderBasis,
    assignment.receivedFromClientMethod,
    assignment.financialInstrumentId,
    assignment.orderInitializerType,
    language,
  ]);

  const getSearchInstrumentsResult = () => {
    if (isListed(instrumentForm, assignment.side)) {
      return getShareOptions;
    } else if (instrumentForm === FUND) {
      return getFundOptions;
    } else if (instrumentForm === STRUCTURED_PRODUCT) {
      return getStructuredOptions;
    } else if (instrumentForm === WARRANT) {
      return getWarrantOptions;
    } else if (
      instrumentForm === PRIVATE_EQUITY ||
      instrumentForm === CO_INVESTMENTS ||
      instrumentForm === PRIVATE_EQUITY_PROFIT_SHARING_LOAN ||
      instrumentForm === CO_INVESTMENTS_PROFIT_SHARING_LOAN
    ) {
      return getPrivateEqOptions;
    }
    return () => [];
  };

  const getSearchDisabled = () => {
    const contractType = selectedPortfolio.contractType;
    const contractName = selectedPortfolio.contractName;
    const shouldCheck =
      contractType === CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE ||
      contractType === CONSULTATIVE_AGREEMENT_TYPE ||
      contractName === CUSTOMER_RELATIONSHIP_AGREEMENT ||
      contractName === CONSULTATIVE_AGREEMENT;
    const orderBasisSelected = orderBasis !== '' && orderBasis !== undefined;

    if (shouldCheck && !orderBasisSelected) {
      return true;
    }

    //check also there is a suitable instrument form selected
    if (!assignment.instrumentForm || assignment.instrumentForm === undefined) {
      return true;
    }

    return false;
  };

  // Functional components require debounce to be used with useCallback
  const getShareOptions = useCallback(
    debounce(
      (input: string) =>
        dispatch(
          searchShare(
            instrumentForm,
            input,
            customerId,
            selectedPortfolio.portfolioId,
            assignment.side,
            assignment.orderBasis,
            assignment.orderInitializerType,
            customerType,
            assignment.buyOrSellClicked
          )
        ),
      DEBOUNCE_DELAY
    ),
    [
      instrumentForm,
      customerId,
      assignment.portfolioId,
      assignment.side,
      assignment.orderBasis,
      assignment.receivedFromClientMethod,
      assignment.orderInitializerType,
      customerType,
      language,
    ]
  );

  const getFundOptions = useCallback(
    debounce(
      (input: string) =>
        dispatch(
          searchInstrument(
            instrumentForm,
            input,
            customerId,
            assignment.portfolioId,
            assignment.side,
            assignment.orderBasis,
            assignment.orderInitializerType,
            customerType,
            'source',
            true,
            assignment.buyOrSellClicked
          )
        ),
      DEBOUNCE_DELAY
    ),
    [
      instrumentForm,
      customerId,
      assignment.portfolioId,
      assignment.side,
      assignment.orderBasis,
      assignment.receivedFromClientMethod,
      assignment.orderInitializerType,
      customerType,
      language,
    ]
  );

  const getStructuredOptions = useCallback(
    debounce(
      (input: string) =>
        dispatch(
          searchStructuredProduct(
            instrumentForm,
            input,
            customerId,
            assignment.portfolioId,
            assignment.side,
            assignment.orderBasis,
            assignment.orderInitializerType,
            customerType,
            assignment.buyOrSellClicked
          )
        ),
      DEBOUNCE_DELAY
    ),
    [
      instrumentForm,
      customerId,
      assignment.portfolioId,
      assignment.side,
      assignment.orderBasis,
      assignment.receivedFromClientMethod,
      assignment.orderInitializerType,
      customerType,
      language,
    ]
  );

  const getWarrantOptions = useCallback(
    debounce(
      (input: string) =>
        dispatch(
          searchWarrant(
            instrumentForm,
            input,
            customerId,
            assignment.portfolioId,
            assignment.side,
            assignment.orderBasis,
            assignment.orderInitializerType,
            customerType,
            assignment.buyOrSellClicked
          )
        ),
      DEBOUNCE_DELAY
    ),
    [
      instrumentForm,
      customerId,
      assignment.portfolioId,
      assignment.side,
      assignment.orderBasis,
      assignment.receivedFromClientMethod,
      assignment.orderInitializerType,
      customerType,
      language,
    ]
  );

  const getPrivateEqOptions = useCallback(
    debounce(
      (input: string) =>
        dispatch(
          searchPrivateEquity(
            instrumentForm,
            input,
            customerId,
            assignment.portfolioId,
            assignment.side,
            assignment.orderBasis,
            assignment.orderInitializerType,
            customerType,
            assignment.buyOrSellClicked
          )
        ),
      DEBOUNCE_DELAY
    ),
    [
      instrumentForm,
      customerId,
      assignment.portfolioId,
      assignment.side,
      assignment.orderBasis,
      assignment.receivedFromClientMethod,
      assignment.orderInitializerType,
      customerType,
      language,
    ]
  );

  return (
    <div className={'row'}>
      <SelectInstrumentForm
        instrumentForm={instrumentForm}
        className="columns small-4"
        focusRef={focusRef}
        orderLineId={assignment._id}
      />

      <div className="columns small-8" key="instrument" data-testkey="selected-instrument-input">
        <InstrumentSearch
          onInstrumentChange={onInstrumentChange}
          value={assignment.financialInstrumentId}
          options={instrumentOptions}
          instrumentForm={instrumentForm}
          isLoading={isLoadingOptions}
          data-testkey="instrument-search-field"
          getOptions={getSearchInstrumentsResult()}
          searchDisabled={getSearchDisabled()}
          receiveMethod={receiveMethod}
          setFocus={setFocus}
          assignment={assignment}
          assignmentLanguage={language}
        />
      </div>
    </div>
  );
};
